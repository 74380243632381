import React from "react";
import logoFooter2 from "../images/logo2_footer (1).png";
import instra1 from "../images/members/image.png";
import instra2 from "../images/members/image (1).png";
import instra3 from "../images/members/image (2).png";
import instra4 from "../images/members/image (3).png";
import instra5 from "../images/members/image (4).png";
import instra6 from "../images/members/image (5).png";
import instra7 from "../images/members/image (6).png";

import formIcon from "../images/form-iocn.png";

import "./footer.css";

export const Footer = () => {
  return (
    <footer className="content-wrapper">
      <div
        className="fluid-engine fe-630e46e8f54688692b3a2c13"
        style={{
          display: "flex",
        }}>
        <div className="col-7 col-sm-12 fe-block fe-block-b97fee880036f3eaf6c8">
          <div
            className="sqs-block accordion-block sqs-block-accordion"
            data-block-type="69"
            id="block-b97fee880036f3eaf6c8">
            <div className="sqs-block-content">
              <ul
                className="accordion-items-container"
                data-should-allow-multiple-open-items=""
                data-is-divider-enabled="true"
                data-is-first-divider-visible="true"
                data-is-last-divider-visible="true"
                data-is-expanded-first-item="true"
                data-accordion-title-alignment="left"
                data-accordion-description-alignment="left"
                data-accordion-description-placement="left"
                data-accordion-icon-placement="right">
                <li className="accordion-item" data-is-open="true">
                  <div
                    className="accordion-divider accordion-divider--top"
                    aria-hidden="true"
                    style={{
                      height: 1,
                      opacity: 0.15,
                    }}></div>
                  <div className="accordion-item__title-wrapper" role="heading">
                    <button
                      className="accordion-item__click-target"
                      aria-expanded="true"
                      id="button-block-b97fee880036f3eaf6c8-0"
                      aria-controls="dropdown-block-b97fee880036f3eaf6c8-0">
                      <span className="accordion-item__title"></span>
                      <div
                        className="accordion-icon-container"
                        data-is-open="false"
                        aria-hidden="true">
                        <div className="plus">
                          <div className="plus__horizontal-line"></div>
                          <div className="plus__vertical-line"></div>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    className="accordion-item__dropdown accordion-item__dropdown--open"
                    role="region"
                    id="dropdown-block-b97fee880036f3eaf6c8-0"
                    aria-labelledby="button-block-b97fee880036f3eaf6c8-0">
                    <div className="accordion-item__description sqsrte-small">
                      <p className="white-space pre-wrap">
                        Whether you're interested in running a pilot test,
                        exploring an affiliate
                        <br />
                        marketing campaign or rethinking your content marketing
                        strategy,
                        <br /> we would welcome a conversation. Email us at{" "}
                        <a href="mailto:mario@spat.media">
                          mario@spat.media
                        </a>{" "}
                        to
                        <br /> start a dialogue.{" "}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="made-nyc">
        <p classNameName="text-center" className="sqsrte-small">
          Made in NYC
        </p>
      </div>
    </footer>
  );
};
