import React from "react";
import dynamic from "../images/dyn.jpg";
import iconFb from "../images/icon-fb.png";
import iconIns from "../images/icon-ins.png";
import iconTw from "../images/icon-tw.png";
import iconYo from "../images/icon-yo.png";
import newsCard from "../images/burger.jpg";
import { redirectionEnvironmentLinks } from "../config";

export const DynamicImage = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const image_id = urlParams.get("image_id");
  const imageName = urlParams.get("image_name");
  // console.log("image_id", image_id);
  return (
    <div className="about-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <br />
          </div>
        </div>
        <div
          className=""
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}>
          <div
            className=""
            style={{
              width: "clamp(400px,70%,70vw)",
            }}>
            <div className="about-right mb-90">
              {/* <script src="http://spat-emped-front-end.s3-website.ap-south-1.amazonaws.com/?showType=published&image_id=d98bfdc1-f573-4d0a-9843-12a2ccdf0e15" data-width="1280" data-height="1280" data-creativeid="9662" data-img="true"></script> */}
              <div className="iframe-wrapper">
                <iframe
                  viewport="width=device-width, initial-scale=1.0"
                  id={`spat-frame-${Math.random()}`}
                  title={`Dynamic content displaying ${imageName}`}
                  src={`${redirectionEnvironmentLinks.embedLink}?showType=preview&image_id=${image_id}`}
                  width="100%"
                  height={"500px"}
                  frameborder="0"
                  allowFullScreen
                  scrolling="no"></iframe>
              </div>
              <div className="section-tittle mb-30 pt-30">
                <h3>ABOUT SPAT MEDIA</h3>
              </div>
              {/* <div className="about-area">
                <p className="about-pera1 mb-25 text-start">
                  SPAT Media, Inc. is driving innovation in digital imagery by
                  turning previously static images into dynamic content
                  marketing and affiliate commerce assets. In doing so, we are
                  empowering creators – from photographers and bloggers to
                  influence's and editorial publishers – by providing access to
                  the tools that will allow them to generate new economic
                  opportunities through their craft.
                </p>

                <p className="about-pera1 mb-25 text-start">
                  The vision for SPAT originated within creative studios and
                  digital media licensing companies, where our founders were
                  exposed to the lack of royalty transparency and proper
                  attribution being granted to photographers. It picked up steam
                  as the founding team recognized the limited innovation in
                  digital imagery and identified the shortcomings of
                  hyperlink-based tools used for generating engagement and
                  affiliate commerce. And it crystallized around our experience
                  in sports, with the insight that sports properties monetize
                  only a fraction of their image assets. With SPAT, 100% of
                  image content can be monetized and generate valuable consumer
                  insights for publishers and IP owners alike.
                </p>
                <p className="about-pera1 mb-25 text-start">
                  SPAT’s patented technology converts digital images into
                  interactive, commerce- enabled experiences that can live
                  within any Web2 or Web3 environment. We are providing tools to
                  help creators, content marketers and editorials drive deeper
                  engagement and monetize content in new. By unlocking context
                  and commerce within a digital photo, an image becomes an
                  immersive micro-campaign. This can drive sponsorship value by
                  creating measurable engagement with previously static,
                  impression-based assets like jersey patches or field board
                  signage. It can enhance PR / earned media efforts by placing
                  an interactive image alongside a press release or editorial
                  piece. And it can generate affiliate revenue streams for any
                  content creator or publisher, making the products depicted in
                  their images shoppable.
                </p>
                <p className="about-pera1 mb-25 text-start">
                  SPAT is addressing the limitations of hyperlink technologies,
                  delivering context, content and a path to purchase within an
                  image on the page where it resides. We use object segmentation
                  to capture user behavior metrics, which help our customers
                  optimize their content strategy. And our transparent royalty
                  ledger pools engagement-based and affiliate marketing revenue
                  that is distributed to designated stakeholders in real-time,
                  thus providing economic empowerment across the creator
                  ecosystem.
                </p>
                <p className="about-pera1 mb-25 text-start">
                  SPAT’s leadership team is based in New York and Los Angeles
                  and is comprised of experts in the fields of design,
                  technology, product development, licensing, marketing &
                  communications, corporate development and strategic finance,
                  with over 125 collective years of experience.{" "}
                </p>
              </div> */}
              <div class="sqs-html-content about-prea">
                <p>
                  SPAT Media, Inc. is driving innovation in digital imagery by
                  turning previously static images into dynamic content
                  marketing and affiliate commerce tools.&nbsp; In doing so, we
                  are empowering creators – from photographers and bloggers to
                  influencers, brands and editorial publishers – by providing
                  access to the tools that will allow them to generate new
                  revenue streams through their craft.&nbsp;{" "}
                </p>
                <p>
                  &nbsp;The vision for SPAT originated within creative studios
                  and digital media licensing companies, where our founders were
                  exposed to the lack of royalty transparency and proper
                  attribution being granted to photographers.&nbsp; It picked up
                  steam as the founding team recognized the limited innovation
                  in digital imagery and identified the shortcomings of
                  hyperlink-based tools used for generating engagement and
                  affiliate commerce. And it crystallized around our experience
                  in sports, with the insight that sports properties monetize
                  only a fraction of their image assets.&nbsp; With SPAT, 100%
                  of image content can be monetized and generate valuable
                  consumer insights for publishers and IP owners alike.
                </p>
                <p>
                  &nbsp;SPAT’s patented technology process converts digital
                  images into interactive, commerce-enabled experiences that can
                  live within any Web2 or Web3 environment.&nbsp; We are
                  providing tools to help creators, content marketers and
                  editorials drive deeper engagement and new revenue
                  opportunities.&nbsp; By unlocking context and commerce, an
                  image becomes an immersive micro-campaign. This can drive
                  sponsorship value by creating measurable engagement with
                  previously static, impression-based assets like jersey patches
                  or field board signage. &nbsp;It can enhance PR / earned media
                  efforts by placing an interactive image alongside a press
                  release or editorial piece. &nbsp;And it can generate
                  affiliate revenue streams for any content creator or
                  publisher, making the products depicted in their images
                  shoppable.
                </p>
                <p>
                  SPAT is addressing the limitations of hyperlink technologies,
                  delivering context, content and a path to purchase within an
                  image on the page where it resides.&nbsp; We use object
                  segmentation to capture user behavior metrics, which help our
                  customers optimize their content strategy.&nbsp; And our
                  transparent royalty ledger pools affiliate marketing revenue
                  that is distributed automatically to designated stakeholders,
                  thus providing economic empowerment across the creator
                  ecosystem and a desperately needed new revenue source for the
                  digital publishing industry.
                </p>
                <p>
                  SPAT’s leadership team is based in New York and Los Angeles
                  and is comprised of experts in the fields of design,
                  technology, product development, licensing, marketing &amp;
                  communications, corporate development and strategic finance,
                  with over 125 collective years of experience.&nbsp;{" "}
                </p>
              </div>
              {/* <div className="section-tittle">
                                    <h3>Unordered list style?</h3>
                                </div> */}
              {/* <div className="about-prea">
                                    <p className="about-pera1 mb-25">The refractor telescope uses a convex lens to focus the light on the eyepiece.
                                        The reflector telescope has a concave lens which means it bends in. It uses mirrors to focus the image that you eventually see.</p>
                                    <p className="about-pera1 mb-25">Collimation is a term for how well tuned the telescope is to give you a good clear image of what you are looking at. You want your telescope to have good collimation so you are not getting a false image of the celestial body.</p>
                                    <p className="about-pera1 mb-25">
                                        My hero when I was a kid was my mom. Same for everyone I knew. Moms are untouchable. They’re elegant, smart, beautiful, kind…everything we want to be. At 29 years old, my favorite compliment is being told that I look like my mom. Seeing myself in her image, like this daughter up top, makes me so proud of how far I’ve come, and so thankful for where I come from.
                                        the refractor telescope uses a convex lens to focus the light on the eyepiece.
                                        The reflector telescope has a concave lens which means it bends in. It uses mirrors to focus the image that you eventually see.
                                        Collimation is a term fo
                                        Moms are like…buttons? Moms are like glue. Moms are like pizza crusts. Moms are the ones who make sure things happen—from birth to school lunch.</p>
                                        <p className="about-pera1 mb-25">
                                        Mount and Wedge. Both of these terms refer to the tripod your telescope sits on. The mount is the actual tripod and the wedge is the device that lets you attach the telescope to the mount.
                                        Moms are like…buttons? Moms are like glue. Moms are like pizza crusts. Moms are the ones who make sure things happen—from birth to school lunch.</p>
                                        <p className="about-pera1 mb-25">
                                        Mount and Wedge. Both of these terms refer to the tripod your telescope sits on. The mount is the actual tripod and the wedge is the device that lets you attach the telescope to the mount.
                                        Moms are like…buttons? Moms are like glue. Moms are like pizza crusts. Moms are the ones who make sure things happen—from birth to school lunch.</p>
                                </div> */}
              <div className="social-share pt-30">
                <div className="section-tittle">
                  <h3 className="mr-20">Share:</h3>
                  <ul>
                    <li>
                      <a href="#">
                        <img src={iconIns} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={iconFb} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={iconTw} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={iconYo} alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <form
                  className="form-contact contact_form mb-80"
                  action="/"
                  id="contactForm">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <textarea
                          className="form-control w-100 error"
                          name="message"
                          id="message"
                          cols="30"
                          rows="9"
                          placeholder="Enter Message"></textarea>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          className="form-control error"
                          name="name"
                          id="name"
                          type="text"
                          placeholder="Enter your name"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          className="form-control error"
                          name="email"
                          id="email"
                          type="email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          className="form-control error"
                          name="subject"
                          id="subject"
                          type="text"
                          placeholder="Enter Subject"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group mt-3 d-flex">
                    <button
                      disabled
                      type="submit"
                      className="button button-contactForm boxed-btn">
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            className=""
            style={{
              width: "clamp(150px,30%,30vw)",
            }}>
            <div className="section-tittle mb-40">
              <h3>Follow Us</h3>
            </div>
            <div className="single-follow mb-45">
              <div className="single-box">
                <div className="follow-us d-flex align-items-center">
                  <div className="follow-social">
                    <a href="#">
                      <img src={iconFb} alt="" />
                    </a>
                  </div>
                  <div className="follow-count">
                    <span>8,045</span>
                    <p>Fans</p>
                  </div>
                </div>
                <div className="follow-us d-flex align-items-center">
                  <div className="follow-social">
                    <a href="#">
                      <img src={iconTw} alt="" />
                    </a>
                  </div>
                  <div className="follow-count">
                    <span>8,045</span>
                    <p>Fans</p>
                  </div>
                </div>
                <div className="follow-us d-flex align-items-center">
                  <div className="follow-social">
                    <a href="#">
                      <img src={iconIns} alt="" />
                    </a>
                  </div>
                  <div className="follow-count">
                    <span>8,045</span>
                    <p>Fans</p>
                  </div>
                </div>
                <div className="follow-us d-flex align-items-center">
                  <div className="follow-social">
                    <a href="#">
                      <img src={iconYo} alt="" />
                    </a>
                  </div>
                  <div className="follow-count">
                    <span>8,045</span>
                    <p>Fans</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="news-poster d-none d-lg-block">
              <img src={newsCard} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
