const prodEmbedLink = "https://content.spat.media/";
const devEmbedLink =
  "http://spat-emped-dev.s3-website.ap-south-1.amazonaws.com/";

const environment = "prod"; // dev , prod;

const redirectionLinks = {
  prod: {
    embedLink: prodEmbedLink,
  },
  dev: {
    embedLink: devEmbedLink,
  },
};

export const redirectionEnvironmentLinks = redirectionLinks[environment];
