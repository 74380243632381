import React from "react";
import { DynamicImage } from "./dynamic-image";
import "./main.css";

export const Main = () => {
  return (
    <main>
      {/* efjhfbehufr */}
      <DynamicImage />
    </main>
  );
};
