import React from 'react'
import logo from '../images/logo.png'


import './header.css'
export const NavBar = () => {
  return (
    <header className='header-announcement-bar-wrapper'>
        <div className="header-display-desktop" data-content-field="site-title">
            <div className="header-title-nav-wrapper">
              <div className="header-title" data-animation-role="header-element">
                  <div className="header-title-logo">
                    <a href="https://www.spat.media/" data-animation-role="header-element">
<img src={logo} alt="SPAT Media"  fetchpriority="high" loading="eager" decoding="async" data-loader="raw"/>
                    </a>
                  </div>
              </div>
                        <div className="header-nav">
              <div className="header-nav-wrapper">
                <nav className="header-nav-list">
  <div className="header-nav-item header-nav-item--collection  header-nav-item--homepage">
    <a href="https://www.spat.media/" data-animation-role="header-element" aria-current="page">
      Demo
    </a>
  </div>

  <div className="header-nav-item header-nav-item--collection">
    <a href="https://www.spat.media/about" target='__blank' data-animation-role="header-element">
      About
    </a>
  </div>

  <div className="header-nav-item header-nav-item--collection">
    <a href="https://www.spat.media/media" target='__blank' data-animation-role="header-element">
      Media
    </a>
  </div>
  <div className="header-nav-item header-nav-item--collection">
    <a href="https://www.spat.media/contact-1" target='__blank' data-animation-role="header-element">
      Contact
    </a>
  </div>
                </nav>
              </div>
            </div>
          
          
        </div>
          </div>
</header>
  )
}
